import Vue from "vue/dist/vue.min";
import Vuex from "vuex";

Vue.use(Vuex);
const DefaultFilter = () => import(/* webpackChunkName: "DefaultFilter" */ "./DefaultFilter.vue");

import store from "../../../store/index";

function init() {
    document.querySelectorAll('[data-vue="default-filter"]').forEach((el) => {
        new Vue({
            el,
            store,
            components: { DefaultFilter },
        });
    });
}

if (document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", () => {
        init();
    });
}
